import { Routes, BrowserRouter, Route } from "react-router-dom";
import { rootRoute } from "./root.route";
import { ForbiddenPage, HomePage } from "pages";
import { PageContainer } from 'layouts';
import { ToastContainer } from "react-toastify";
export const RootRoute = () => {
  return (
    <>
      <Routes>
        <Route index Component={HomePage} />
        <Route path="store" Component={PageContainer}>
          {rootRoute.map((item) => (
            <Route key={item.path} path={item.path} Component={item.Component} />
          ))}
        </Route>
        <Route path="*" Component={ForbiddenPage} />
      </Routes>
      <ToastContainer />
  </>
  );
};
