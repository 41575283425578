import { PageHeader, ProductCard, ProductMain } from "components";
import { VNDong } from "lib/utils";
export const CategoryContent = ({ data }) => {
  const productMain = data && data[0];
  const secondItems = data && data.slice(0, 2);

  return (
    <>
      <PageHeader title="Các sản phẩm nổi bật" pageLeft>
        <div className="flex flex-col gap-4 max-md:pb-4">
          {/* <p className="description mt-2 max-md:hidden">
            Information about products: Visionary creations are among the
            world’s most intricate designs. This delicate pendant is a timeless
            design.
          </p> */}

          {productMain ? (
            <div className="product-main grid gap-4 md:grid-cols-3 md:mt-10">
              <div className="row-span-1 md:col-span-2">
                <ProductMain
                  categoryId={productMain?.id}
                  name={productMain?.name}
                  slug={productMain?.slug}
                  price={VNDong.format(productMain?.sell_price || 210000)}
                  image={productMain?.images[0] || ""}
                />
              </div>
              <div className="row-span-1 grid max-md:grid-cols-2 gap-2 md:col-span-1 md:grid-rows-2 md:gap-4">
                {secondItems &&
                  secondItems.map((item, index) => (
                    <div
                      key={`${item?.id}-${index}`}
                      className="col-span-1 md:row-span-1"
                    >
                      <ProductCard
                        categoryId={item?.id}
                        slug={item?.slug}
                        name={item?.name}
                        price={VNDong.format(item?.sell_price || 277000)}
                        image={item?.images[0] || ""}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </PageHeader>
      <PageHeader flip contentClassName="mt-8" pageRight>
        <div className="grid grid-cols-2 gap-x-2 gap-y-5 pb-4 md:pl-16 md:pr-16 md:gap-x-14 md:pb-20 w-full">
          {data && data?.length
            ? data.map((item, index) => (
                <div key={`${item?.name}-${index}`} className="col-span-1">
                  <ProductCard
                    categoryId={item?.id}
                    slug={item?.slug}
                    name={item?.name}
                    price={VNDong.format(item?.sell_price || 230000)}
                    image={item?.images[0] || ""}
                  />
                </div>
              ))
            : null}
        </div>
      </PageHeader>
    </>
  );
};
