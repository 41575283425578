import { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "components";
import { ROUTER_POSITION } from "lib/constants";
import { useAppContext } from "contexts";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./PageContainer.scss";

export const PageContainer = () => {
  const location = useLocation();
  const pageRef = useRef();
  const { handleSetCurrentPage } = useAppContext();

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);
  
  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* right swipe */
        console.log('R')
        setTimeout(() => {
          onScrollRight();
        }, 200);
      } else {
        setTimeout(() => {
          console.log('L')
          onScrollLeft();
        }, 200);
      }
    } else {
      if (yDiff > 0) {
        /* down swipe */
      } else {
        /* up swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }  

  useEffect(() => {
    onScrollLeft();
    const handlePosition = () => {
      handleSetCurrentPage(ROUTER_POSITION[location.pathname]);
    };
    handlePosition();
  }, [location]);

  const autoScroll = (position) => {
    const element = document.getElementById(position);
    element.scrollIntoView({ behavior: "smooth", block: 'center' });
  };

  const onScrollLeft = () => {
    autoScroll("scroll-start");
  };

  const onScrollRight = () => {
    autoScroll("scroll-end");
  };

  return (
    <>
      <div ref={pageRef} className="relative overflow-x-hidden app-container flex flex-col w-full h-full mx-auto container max-md:p-6 flex flex-row-reverse md:p-18 overflow-y-hidden">
        <div
          id="scroll-start"
          className="absolute left-0"
        ></div>
        <div
          id="page-content"
          className="page-container relative flex-1 flex flex-row h-full"
        >
          <div className="page-cover flip"></div>
          <Outlet />
          {/* <ToastContainer /> */}
        </div>
        <div
          id="scroll-end"
          className="block-end absolute w-1 h-1"
        ></div>
        <div
          id="nav-container"
          className="fixed bottom-0 left-0 right-0 w-full nav-container md:top-0 md:bottom-auto md:flex md:p-8 md:items-center"
        >
          <Navbar />
        </div>
      </div>
    </>
  );
};
