import { toast } from 'react-toastify';
export const useToast = () => {

    const success = (desc) => {
        toast.success(desc, {
            position: 'top-center',
        })
    }

    const error = (desc) => {
        toast.error(desc, {
            position: 'top-center'
        })
    }

    return { success, error }
}