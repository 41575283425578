import { useEffect, useState } from 'react'
import { CartContent } from "components";
import { getCarts } from "services";

export const CartPage = () => {
  const [carts, setCarts] = useState([])
  useEffect( () => {
    fetchCart();
  }, [])

  const fetchCart = () => {
    getCarts().then( res => {
      setCarts(res.data)
    })
  }

  return <CartContent data={carts} fetchList={fetchCart} />;
};
