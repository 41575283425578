import { useMemo, useState } from "react";
import Slider from "react-slick";
import iconPlay from "assets/icons/ic_play.svg";
import btnBack from "assets/icons/btn-back.svg";
import btnNext from "assets/icons/btn-next.svg";

import classnames from "classnames";
import "./ProductImageAnimate.scss";

const NextArrow = ({ onClick }) => {
  return <img onClick={onClick} src={btnNext} alt="" />;
};

const BackArrow = ({ onClick }) => {
  return <img onClick={onClick} src={btnBack} alt="" />;
};

export const ProductImageAnimate = ({ images, video }) => {
  const [positionImage, setPositionImage] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: images.length > 5 ? 5 : images.length,
    slidesToScroll: 1,
    className: "1",
  };

  const contentToView = useMemo(() => {
    if (positionImage === 0) {
      return (
        <video width="251" height="270" autoPlay={true} loop muted>
          <source src={video} type="video/mp4" />
        </video>
      );
    }

    return <img className="image-content" src={images[positionImage]} alt="" />;
  }, [positionImage, images, video]);

  return (
    <div className="flex flex-col justify-center w-full gap-4">
      <div className="video-wrapper text-center">{contentToView}</div>
      <Slider
        {...settings}
        className="images-slider"
        nextArrow={<NextArrow />}
        prevArrow={<BackArrow />}
      >
        {images?.length
          ? images.map((item, index) => (
              <div
                onClick={() => setPositionImage(index)}
                key={index}
                className="list-image-wrapper"
              >
                <div className="relative">
                  <img
                    className={classnames("img-item", {
                      active: positionImage === index,
                    })}
                    src={item}
                    alt="product img"
                  />
                  {index === 0 && (
                    <>
                      <div className="play-wrapper absolute top-0"></div>
                      <div className="play-icon w-full flex justify-center items-center absolute top-0">
                        <img src={iconPlay} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))
          : null}
      </Slider>
    </div>
  );
};
