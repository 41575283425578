import { useState, useEffect } from "react"
import { HistoryContent } from "components";
import { getOrdersApi } from "services"

export const HistoryPage = () => {
  const [ orders, setOrders ] = useState([])

  useEffect( () => {
    getOrdersApi().then( res => {
      const { data } = res
      setOrders(data?.items)
    })
  }, [])
  return <HistoryContent data={orders} />;
};
