import { useEffect, useState } from "react";
import { CategoryContent } from "components";
import { getProductsApi } from "services";
import { useAppContext } from "contexts";

export const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [ page, setPage ] = useState(1)
  const { isScrollToBottom } = useAppContext()

  useEffect(() => {
    getProductsApi({page: page, per_page: 100}).then((res) => {
      setCategories( (prev) => {
        const temp = prev.concat(res?.data?.items)
        return temp;
      });
    });
  }, [page]);

  useEffect( () => {
    if (isScrollToBottom) {
      setPage(page + 1)
    }
  }, [isScrollToBottom, setPage])

  return <CategoryContent data={categories} />;
};
