import { addToCart, getCarts, updateCartById } from "services";
import { useToast } from "hooks";

export const useCart = () => {
  const { success } = useToast()
  const getQuantityProductInCart = async (productId) => {
    const carts = await getCarts();
    const productInCart = carts.data.find(
      (item) => +item?.id === +productId
    );
    if (productInCart) {
      return productInCart?.qty;
    }
    return 0;
  };


  const handleAddToCart = async (productId) => {
    return addToCart(productId, 1).then( () => {
      success('Đã thêm vào giỏ hàng thành công !')
    })
  };

  const handleReduceItemInCart = async (productId) => {
    const quantity = await getQuantityProductInCart(productId);
    quantity && updateCartById(productId, quantity - 1).then((res) => {
      console.group("UPDATED");
      success('Cập nhật giỏ hàng thành công !')

    });
  };

  return { handleAddToCart, handleReduceItemInCart };
};
