// pages
import {
  CategoryPage,
  ProductPage,
  CartPage,
  CheckoutPage,
  ScanCodePage,
  HistoryPage,
  OrderDetailPage,
  ForbiddenPage,
} from "pages";
export const rootRoute = [
  {
    path: "category",
    Component: CategoryPage,
  },
  {
    path: "product",
    Component: ProductPage,
  },
  {
    path: "product/:categoryId",
    Component: ProductPage,
  },
  {
    path: "cart",
    Component: CartPage,
  },
  {
    path: "checkout",
    Component: CheckoutPage,
  },
  {
    path: "scan-code",
    Component: ScanCodePage,
  },
  {
    path: "history",
    Component: HistoryPage,
  },
  {
    path: "order/:code",
    Component: OrderDetailPage,
  },
];
