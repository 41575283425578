import { orderStatus } from 'lib/utils'
import classnames from 'classnames';
import "./UserInfo.scss";

export const UserInfo = ({
  id,
  status,
  name,
  address,
  phone,
  paymentMethod,
}) => {

  const classTag = classnames('status-tag', {
    'success': status === 'success',
    'created' : status === 'pending'
  })

  const renderItem = (label, value) => {
    return (
      <div className="group-info md:col-span-1">
        <label>{label}</label>
        <p>{value}</p>
        {/* <label className="md:col-span-1">{label}:</label>
        <span className="text-right col-span-3 md:text-left">{value}</span> */}
      </div>
    );
  };

  return (
    <div className="info-container flex flex-col gap-4">
      <div className="block-id flex flex-row gap-2">
        <h3 className="order-id">#{id}</h3>
        <div className={classTag}>{orderStatus(status)}</div>
      </div>
      <div className="block-user gap-2 grid md:grid-cols-2 md:gap-x-32">
        {renderItem('Tên', name)}
        {renderItem('Địa chỉ', address)}
        {renderItem('Số điện thoại', phone)}
        {renderItem('Thanh toán', paymentMethod)}
      </div>
    </div>
  );
};
