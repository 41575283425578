import { useState, useEffect, createRef } from "react";
import { InputText } from "components";
import classnames from "classnames";
import "./SelectBox.scss";
export const SelectBox = ({
  options,
  placeholder,
  label,
  name,
  rest,
  onChange = () => {},
}) => {
  const [selectVal, setSelectVal] = useState("");
  const [isShow, setIsShow] = useState(false);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const selectRef = createRef();
  useOutsideAlerter(selectRef);
  useEffect(() => {
    onChange(selectVal?.value);
  }, [selectVal, onChange]);

  const handleChooseItem = (item) => {
    setSelectVal(item);
    setIsShow(!isShow);

  };

  return (
    <div
      className="w-full select-box-container relative"
      onBlur={() => {
        setTimeout( () => {
          setIsShow(false)
        },200)
      }}
    >
      <InputText
        name={name}
        className="w-full"
        value={selectVal?.label || ""}
        placeholder={placeholder}
        onClick={() => setIsShow(!isShow)}
        label={label}
        readonly
        // {...rest}
      />

      {options?.length ? (
        <ul
          className={classnames("w-full option-list", {
            hidden: !isShow,
          })}
        >
          {options.map((item, index) => (
            <li
              className="option"
              key={`${item.label}-${index}`}
              value={item?.value}
              onClick={() => handleChooseItem(item)}
            >
              {item?.label}
            </li>
          ))}
        </ul>
      ) : (
        isShow && <div className="no-option">No option...</div>
      )}
    </div>
  );
};
