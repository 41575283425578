import { BaseService } from "./base/BaseService";
import { getUserId } from "lib/utils";
import axiosCustomer from "./base/customerClient";

// order by: id, pid, slug, name, sku, sell_price
// order_dir: asc, desc
export const getProductsApi = (
  pagination = { page: 1, per_page: 30 },
  params = { keyword: "", category: "", order_by: "id", order_dir: "desc" }
) => {
  return BaseService.get("/products", {
    ...params,
    page: pagination.page,
    per_page: pagination.per_page,
  });
};
export const getProductBySlugApi = (product_slug = "") => {
  return BaseService.get(`/products/${product_slug}`);
};

export const addToCart = (product_id, qty) => {
  return BaseService.post("/cart", { user_id: getUserId(), product_id, qty });
};

export const updateCartById = (item_id, qty) => {
  return BaseService.put(`/cart/${item_id}`, { qty, user_id: getUserId() });
};

export const getCarts = () => {
  return BaseService.get("/cart", { user_id: getUserId() });
};

export const createOrderApi = (
  payload = {
    cus_name: "",
    cus_phone: "",
    cus_address: "",
    cus_province: "",
    cus_district: "",
    cus_ward: "",
  }
) => {
  return BaseService.post("/orders", { user_id: getUserId(), ...payload });
};

export const getOrdersApi = () => {
  return BaseService.get("/orders");
};

export const getOrderDetail = (code) => {
  return BaseService.get(`/orders/${code}`)
}

export const getStoresApi = (
  payload = { province_code: "", district_code: "", ward_code: "" }
) => {
  return BaseService.get("/stores", payload);
};

export const filterCustomerByPhoneApi = async (phone) => {
  return axiosCustomer.post('/stage/getCustomerByPhone', {phone: phone})
};

