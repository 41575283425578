import { useRef, useState } from "react";
import { PageHeader, UserInfo, ProductItem } from "components";
import { VNDong } from "lib/utils";
import arrowDown from "assets/icons/show-down-blue.svg";
import classnames from "classnames";
import "./HistoryContent.scss";
export const HistoryContent = ({ data }) => {
  const orderShowMoreRef = useRef({});
  const [scrollItemCode, setScrollItemCode] = useState({});
  const isShowScroll = (items, code) => {
    const isCheck = items?.length > 2;
    orderShowMoreRef.current[code] = isCheck ? false : true;
    return isCheck;
  };

  const handleShowMore = (code) => {
    setScrollItemCode((prev) => {
      return {
        ...prev,
        [code]: !prev[code],
      };
    });
  };

  return (
    <>
      <PageHeader title="Lịch sử đơn hàng" heightFull pageLeft>
        <div className="history-container mb-4 w-full">
          {data?.length
            ? data.map((info, index) => {
                const {
                  code,
                  cus_name,
                  cus_phone,
                  cus_province,
                  cus_ward,
                  cus_district,
                  cus_address,
                  amount,
                  status,
                  payment_method,
                  items,
                } = info;
                return (
                  <div key={`${code}-${index}`}>
                    <UserInfo
                      id={code}
                      status={status}
                      name={cus_name}
                      address={`${cus_address}, ${cus_ward}, ${cus_district}, ${cus_province}`}
                      phone={cus_phone}
                      paymentMethod={payment_method}
                    />
                    <div
                      className={classnames(
                        "product-wrapper scrollbar-custom  flex flex-col mt-4 gap-2",
                        {
                          "overflow-y-scroll": scrollItemCode[code],
                        },
                        { "overflow-y-hidden": !scrollItemCode[code] }
                      )}
                    >
                      {items &&
                        items.map((item, index) => (
                          <ProductItem
                            key={`${item.name}-${index}`}
                            id={`${item.name}-${index}`}
                            image={item?.image}
                            qty={item.qty}
                            productTitle={item.name}
                            price={+item.total}
                          />
                        ))}
                    </div>
                    {isShowScroll(items, code) && (
                      <p className="btn-show-more flex flex-row gap-2 mt-2">
                        <img
                          className={classnames({
                            "show-less": scrollItemCode[code],
                          })}
                          src={arrowDown}
                          alt="arrow down icon"
                        />
                        <span onClick={() => handleShowMore(code)}>
                          {scrollItemCode[code] ? "Ít hơn" : "Xem thêm"}
                        </span>
                      </p>
                    )}

                    <div className="total-price flex justify-between mb-4 mt-4">
                      <label>Tổng tiền </label>
                      <span> {VNDong.format(amount)}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </PageHeader>
      <PageHeader flip heightFull pageRight></PageHeader>
    </>
  );
};
