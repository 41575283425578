import { useAppNavigate } from "hooks";
import { ROUTER_PATH } from "lib/constants";
import mainImage from "assets/images/main-product-img.png"
import mainImageMb from "assets/images/product-main-mb.png"

import "./ProductMain.scss";
export const ProductMain = ({ categoryId, name, price, image }) => {
  const { handleNavigatePage } = useAppNavigate();

  return (
    <div
      onClick={() => handleNavigatePage(`${ROUTER_PATH.PRODUCT}/${categoryId}`)}
      className="product-main-container relative"
    >
      <img className="max-md:hidden h-full w-full" src={mainImage} alt="" />
      <img className="md:hidden h-full w-full" src={mainImageMb} alt="" />
      <div className="product-content bottom-6 right-4 left-7 absolute">
        <p className="cut-name">{name}</p>
        <span>{price}</span>
      </div>
    </div>
  );
};
