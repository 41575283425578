import axios from "axios";
const CUSTOMER_API = process.env.REACT_APP_CUSTOMER_API;

export const axiosCustomer = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosCustomer.interceptors.request.use(
  function (config) {
    config.baseURL = CUSTOMER_API;
    //   const accessToken = getAccessTokenCookie();
    //   config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosCustomer.interceptors.response.use(
  async (response) => {
    // await sleep();
    return response.data;
  },
  async (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axiosCustomer;
