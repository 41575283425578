import { VNDong } from "lib/utils";
import "./ProductItem.scss";
export const ProductItem = ({ image, productTitle, price, qty }) => {
  return (
    <div className="item-container flex gap-2">
      <div className="image-wrapper flex-none">
        <img className="w-full h-full" src={image} alt="product img" />
      </div>
      <div className="flex flex-col content-center">
        <span className="cut-name title cut-name row-span-1 col-span-2">{productTitle}</span>
        <span className="price row-span-1 col-span-2">{VNDong.format(price)}</span>
        <span className="quantity">Số lượng: {qty}</span>
      </div>
    </div>
  );
};
