export const HomeIcon = (props) => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.375 7.12516L9.5 1.5835L16.625 7.12516V15.8335C16.625 16.2534 16.4582 16.6561 16.1613 16.9531C15.8643 17.25 15.4616 17.4168 15.0417 17.4168H3.95833C3.53841 17.4168 3.13568 17.25 2.83875 16.9531C2.54181 16.6561 2.375 16.2534 2.375 15.8335V7.12516Z"
        stroke="#919EAB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.125 17.4167V9.5H11.875V17.4167"
        stroke="#919EAB"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
