import { useMemo } from "react";
import { PageHeader, ProductCard, GroupQuantity } from "components";
import { VNDong } from "lib/utils";
import { useCart, useAppNavigate } from "hooks";
import { ROUTER_PATH } from "lib/constants";
import "./CartContent.scss";
export const CartContent = ({ data, fetchList }) => {
  const { handleAddToCart, handleReduceItemInCart } = useCart();
  const { handleNavigatePage } = useAppNavigate();
  const pageItemsMain = data && data.length > 4 ? data.slice(0, 4) : data;
  const pageList = data && data.length > 4 ? data.slice(4) : [];

  const totalSummary = useMemo(() => {
    if (data.length) {
      if (data.length === 1) {
        const product = data[0];
        return +product?.product_info?.sell_price * product?.qty;
      }
      const count = data.reduce((prev, current) => {
        if (prev?.product_info) {
          return (
            +prev?.product_info?.sell_price * prev.qty +
            +current?.product_info?.sell_price * current.qty
          );
        }

        return prev + +current?.product_info?.sell_price * current.qty;
      });

      return count;
    }

    return 0;
  }, [data]);

  const renderTotalPrice = useMemo(() => {
    return (
      <div className="bg-white checkout-btn-list-page flex items-center justify-between bottom-0 left-0 absolute col-span-2 w-11/12 h-12 md:w-full">
        {/* <ButtonNext name="Check out" link={ROUTER_PATH.CHECKOUT} /> */}
        <div className="total">
          <span className="total-label">Total: </span>
          <span className="total-price">{VNDong.format(totalSummary)}</span>
        </div>
        <button
          onClick={() => handleNavigatePage(ROUTER_PATH.CHECKOUT)}
          className="btn secondary w-5/12"
        >
          Tiếp theo
        </button>
      </div>
    );
  }, [totalSummary]);

  const onAddItem = (productId) => () => {
    handleAddToCart(productId).then(() => fetchList());
  };

  const handleReduceItem = (productId) => () => {
    handleReduceItemInCart(productId).then(() => {
      setTimeout(() => {
        fetchList();
      }, 1000);
    });
  };

  return (
    <>
      <PageHeader title="Giỏ hàng" contentClassName="w-full" heightFull pageLeft>
        <div className="flex flex-col h-full w-full relative">
          <div className="grid grid-cols-2 gap-8 md:pr-24">
            {pageItemsMain
              ? pageItemsMain.map((item, index) => (
                  <div key={`${item?.name}-${index}`} className="col-span-1">
                    <ProductCard
                      name={item?.product_info?.name}
                      price={VNDong.format(item?.product_info?.sell_price)}
                      image={item?.product_info?.images[0]}
                      categoryId={item?.product_info?.id}
                    />
                    <GroupQuantity
                      onAdd={onAddItem(item?.product_info?.id)}
                      onReduce={handleReduceItem(item?.id)}
                      quantity={item?.qty}
                    />
                  </div>
                ))
              : null}
          </div>
          {pageList && pageList?.length ? null : renderTotalPrice}
        </div>
      </PageHeader>
      <PageHeader flip contentClassName="mt-8 md:h-5/6" pageRight>
        <div className="flex flex-col h-full mt-8 md:mt-0 relative">
          <div className="grid grid-cols-2 gap-8 pb-14 md:pl-16 md:pr-16 md:gap-x-14 w-full">
            {pageList && pageList?.length
              ? pageList.map((item, index) => (
                  <div key={`${item.name}-${index}`} className="col-span-1">
                    <ProductCard
                      name={item?.product_info?.name}
                      price={VNDong.format(item?.product_info?.sell_price)}
                      image={item?.product_info?.images[0]}
                      categoryId={item?.product_info?.id}
                    />
                    <GroupQuantity
                      quantity={item?.qty}
                      onAdd={onAddItem(item?.product_info?.id)}
                      onReduce={handleReduceItem(item?.id)}
                    />
                  </div>
                ))
              : null}
            {pageList && pageList?.length ? renderTotalPrice : null}
          </div>
        </div>
      </PageHeader>
    </>
  );
};
