import { createContext, useContext, useMemo, useState } from "react";
import { useCountry } from "hooks";
export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const { states, districts, wards, uniqueData } = useCountry();
  const [userId, setUserId] = useState('0');
  const [currentPage, setCurrentPage] = useState(0);
  const [classToFlip, setClassToFip] = useState("");
  const [isScrollToBottom, setIsScrollToBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statuesUnique, setStatuesUnique] = useState([])
  const [districtsUnique, setDistrictsUnique] = useState([])
  const [wardsUnique, setWardsUnique] = useState([])

  const setupCountry = () => {
    if (!statuesUnique.length) {
      setStatuesUnique([...uniqueData(states)])
    }
    if (!districtsUnique.length) {
      setDistrictsUnique([...uniqueData(districts)])
    }
    if (!wardsUnique.length) {
      setWardsUnique([...uniqueData(wards)])
    }
  }

  const handleSetCurrentPage = (pagePosition) => {
    setCurrentPage((prev) => {
      if (pagePosition > prev) {
        setClassToFip("animation-right-to-left page-left");
      } else if (pagePosition < prev) {
        setClassToFip("animation-left-to-right page-right");
      }

      return pagePosition;
    });
  };

  const handleSetScrollToBottom = () => {
    setIsScrollToBottom( () => {
      setTimeout( () => {
        setIsScrollToBottom(false)
      }, 2000)
      return true
    })
  }

  const values = useMemo(() => {
    return {
      currentPage,
      classToFlip,
      isScrollToBottom,
      userId,
      isLoading,
      statuesUnique,
      districtsUnique,
      wardsUnique,
      handleSetCurrentPage,
      handleSetScrollToBottom,
      setUserId,
      setIsLoading,
      setupCountry,
    };
  }, [currentPage, classToFlip, isScrollToBottom, userId, isLoading, statuesUnique, statuesUnique, wardsUnique]);

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
