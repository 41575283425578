import axiosClient from "./axiosClient";

const get = async (path = "", params = {}) => {
  return axiosClient.get(path, { params });
};

const post = async (path = "", data = {}) => {
  return await axiosClient.post(path, data);
};

const put = (path = "", data = {}) => {
  return axiosClient.put(path, data);
};

const patch = async (path = "", data = {}) => {
  return axiosClient.patch(path, data);
};

const remove = async(path = "") => {
  return axiosClient.delete(path);
};

export const BaseService = {
  get,
  post,
  put,
  patch,
  remove,
}
