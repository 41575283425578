import jwt_decode from "jwt-decode";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getAuthUrl, exchangeCode } from "services";
import { setLoginInfo, getAccessTokenCookie } from "lib/utils";
import { useAppContext } from "contexts";
import { useToast } from "hooks";
export const useAuthentication = () => {
  const { setUserId } = useAppContext();
  const [searchParams] = useSearchParams();
  const { error: toastError } = useToast();
  const navigate = useNavigate();
  const { origin } = window.location;

  const getLinkAuthentication = async () => {
    const resData = await getAuthUrl(origin);
    const { url } = resData.data;
    return url;
  };

  const openAuthenticationWindow = async () => {
    const authUrl = await getLinkAuthentication();
    window.open(authUrl, "_self");
  };

  const authentication = async () => {
    // const loginInfo = getLoginInfo();
    const access_token = getAccessTokenCookie();

    if (!access_token) {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const error = searchParams.get("error") 

      if (error) {
        toastError(error)
        navigate('403');
        return 
      } else if (code && state) {
        const { data } = await exchangeCode(origin, code);
        if (data?.access_token) {
          const payload = jwt_decode(data.access_token);
          setUserId(payload?.sub);
          setLoginInfo(data);
        } else {
          await openAuthenticationWindow();
        }
      } else {
        await openAuthenticationWindow();
      }
    }
  };

  return { authentication };
};
