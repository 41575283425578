import "./InputText.scss";
import classnames from "classnames";
export const InputText = ({
  label = "",
  name = "",
  placeholder = "",
  value = "",
  className = "",
  readonly = false,
  onChange = (value) => {},
  onClick = () => {},
  onBlur = () => {},
  rest,
}) => {
  const inputClass = classnames(`group-input-text w-full relative ${className}`);
  return (
    <div className={inputClass} onClick={onClick} onBlur={onBlur}>
      <label>{label || placeholder}</label>
      <input value={value} name={name} type="text" className="w-full 123" onChange={onChange} readOnly={readonly} />
    </div>
  );
};
