import { useAppNavigate } from "hooks";
import { ROUTER_PATH } from "lib/constants";
import { HomeIcon, BookIcon, CartIcon, ProductIcon  } from "components/icons";
import arrowRight from 'assets/icons/fi_arrow-right.svg'
import arrowLeft from 'assets/icons/fi_arrow-left.svg'

import classNames from "classnames";
import "./Navbar.scss";
export const Navbar = () => {
  const { handleNavigatePage, handleNextPage, handlePrevPage } =
    useAppNavigate();

  const navBars = [
    {
      name: "Trang chủ",
      img: <HomeIcon />,
      link: ROUTER_PATH.HOME,
      pathName: '/'
    },
    {
      name: "Sản phẩm",
      img: <ProductIcon />,
      link: ROUTER_PATH.CATEGORY,
      pathName: 'category',
      subPath: ['product'],
    },
    {
      name: "Giỏ hàng",
      img: <CartIcon />,
      link: ROUTER_PATH.CART,
      pathName: 'cart',
      subPath: ['checkout', 'order']
    },
    {
      name: "Lịch sử",
      img: <BookIcon />,
      link: ROUTER_PATH.HISTORY,
      pathName: 'history'
    },
  ];

  const handleNavigate = (item) => {
    handleNavigatePage(item.link);
  };

  const isActive = (pathName, subPath) => {
    const arrayPath = window.location.pathname.split('/')
    return arrayPath.some( item => item === pathName || subPath?.some( path => path === item))
  }

  const renderIcon = (pathName, subPath) => {
    const activeColor = isActive(pathName, subPath) ? '#FDA83E' : null;
    switch(pathName) {
      case '/':
        return <HomeIcon color={activeColor} />
      case 'category':
        return <ProductIcon color={activeColor} />
      case 'cart':
        return <CartIcon color={activeColor} />
      case 'history': 
        return <BookIcon color={activeColor} />
      default:
        return <></>
    }
  }

  return (
    <div className="nav-bar flex pt-2 pb-4 content-center w-full justify-around md:flex-col md:gap-8">
      {navBars.map((item) => (
        <div
          key={item.name}
          className="group-category flex flex-col items-center"
          onClick={() => handleNavigate(item)}
        >
          <div className={classNames('image flex items-center justify-center', {
            'active': isActive(item.pathName, item.subPath)
          })}>
            {renderIcon(item.pathName, item.subPath)}
          </div>
          <div className={classNames('category-name mt-2', {'active': isActive(item.pathName, item.subPath)})}>{item.name}</div>
        </div>
      ))}
      <div className="btn-history max-md:hidden flex flex-col gap-5 pt-6 md:justify-end">
        <div
          onClick={() => handleNextPage()}
          className="nav-navigate flex flex-col items-center"
        >
          <div className="image flex items-center justify-center"><img src={arrowRight} alt="" /></div>
        </div>
        <div
          onClick={() => handlePrevPage()}
          className="nav-navigate flex flex-col items-center"
        >
          <div className="image flex items-center justify-center">
            <img src={arrowLeft} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
