export const USER_ID = '0'
export const ROUTER_PATH = {
    HOME: '/',
    CATEGORY: '/store/category',
    PRODUCT: '/store/product',
    CART: '/store/cart',
    CHECKOUT: '/store/checkout',
    SCAN_CODE: '/store/scan-code',
    HISTORY: '/store/history',
    ORDER: '/store/order',

}

export const ROUTER_POSITION = {
    [ROUTER_PATH.HOME]: 1,
    [ROUTER_PATH.CATEGORY]: 2,
    [ROUTER_PATH.PRODUCT]: 3,
    [ROUTER_PATH.CART]: 4,
    [ROUTER_PATH.CHECKOUT]: 5,
    [ROUTER_PATH.ORDER]: 6,
    [ROUTER_PATH.HISTORY]: 7
}
