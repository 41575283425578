import { ButtonNext } from "components";
import { ROUTER_PATH } from "lib/constants";
import { useAppNavigate } from "hooks";
import "./CollectionSlider.scss";
export const CollectionSlider = () => {
  const { handleNavigatePage } = useAppNavigate()
  return (
    <div className="flex flex-col justify-center items-center slider-container pl-4">
      <p className="e-catalog text-2xl font-normal mt-8">E-CATALOGUE</p>
      <p className="e-desc text-lg font-normal text-center">Các sản phẩm nổi bật của thương hiệu PNJ</p>
      <button onClick={ () => handleNavigatePage(ROUTER_PATH.CATEGORY)} className="btn secondary mt-6 w-28">Start</button>
    </div>
  );
};
