import { useEffect, useMemo, useState } from "react";
import { PageHeader, InputText } from "components";
import { useAppNavigate, useToast, useDebounce } from "hooks";
import { SelectBox } from "components";
import { ROUTER_PATH } from "lib/constants";
import { createOrderApi, getStoresApi, filterCustomerByPhoneApi } from "services";
import { useForm, Controller } from "react-hook-form";
import { useAppContext } from "contexts";

import classnames from "classnames";
import "./CheckoutContent.scss";
export const CheckoutContent = () => {
  const { success, error } = useToast();
  const [nameTemp, setNameTemp] = useState('')
  const [stores, setStores] = useState([]);
  const rules = { required: true };
  const {
    getValues,
    setValue,
    reset,
    watch,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      cus_name: "",
      cus_phone: "",
      cus_address: "",
      cus_province: "",
      cus_district: "",
      cus_ward: "",
      store_code: "",
      receipt_method: "Y",
    },
  });
  const { setIsLoading,  statuesUnique, districtsUnique, wardsUnique } = useAppContext();
  const { handleNavigatePage } = useAppNavigate();
  const formStateVal = watch("cus_province");
  const formDistrictVal = watch("cus_district");
  const receiptMethod = watch("receipt_method");
  const phoneVal = watch("cus_phone");

  const searchPhone = useDebounce(phoneVal, 500)
  const shippings = [
    {
      label: "Giao hàng tận nơi",
      value: "N",
    },
    {
      label: "Nhận tại cửa hàng",
      value: "Y",
    },
  ];

  useEffect(() => {
    const handleGetStores = async () => {
      const { data } = await getStoresApi({ province_code: formStateVal });
      if (data) {
        const finalData = data?.map((item) => ({
          value: item.code,
          label: item.name,
        }));

        setStores([...finalData]);
      }
    };

    if (formStateVal) {
      handleGetStores();
    }

  }, [formStateVal]);

  const districts = useMemo(() => {
    return districtsUnique.filter(
      (district) => district?.stateCode === formStateVal
    );
  }, [formStateVal]);

  const wards = useMemo(() => {
    if (!formDistrictVal) {
      return wardsUnique.filter((ward) => ward?.stateCode === formStateVal);
    }

    return wardsUnique.filter(
      (ward) =>
        ward?.districtCode === formDistrictVal &&
        ward?.stateCode === formStateVal
    );
  }, [formDistrictVal, formStateVal]);

  const nameVal = useMemo( () => {
    let val = ''
    if (searchPhone) {
      const temp = searchPhone;
      setIsLoading(true)
      filterCustomerByPhoneApi(temp).then(res => {
        const { data } = res
        const customerData = data.find(item => item?.telf1 === temp)
        setIsLoading(false)
        if (!customerData) {
          return setValue('cus_name', data[0]?.name1)
        }
        return setValue('cus_name', customerData?.name1)
      })

      return val
    }
    return ''

  }, [searchPhone])

  const submitForm = () => {
    if (!isValid) {
      error("Vui lòng điền đầy đủ thông tin!");
      return;
    }
    if (receiptMethod === "Y") {
      setValue('cus_ward', '001')
      setValue('cus_address', 'none')

    }
    const orderInformation = {
      ...getValues(),
    };

    setIsLoading(true);
    createOrderApi(orderInformation)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          reset();
          success("Đặt hàng thành công !");
          handleNavigatePage(`${ROUTER_PATH.ORDER}/${res?.data?.code}`);
        } else {
          error(res.message);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  };

  const handleSetStore = (val) => {
    setValue("store_code", val);
  };

  const onChooseShipping = (val) => {
    setValue("receipt_method", val);
  };

  const renderShippingMethod = useMemo(() => {
    const receiptMethod = getValues().receipt_method;
    return shippings.map((item) => (
      <span
        key={item?.value}
        onClick={() => onChooseShipping(item?.value)}
        className={classnames("tag text-center basis-1/2", {
          active: item?.value === receiptMethod,
        })}
      >
        {item?.label}
      </span>
    ));
  }, [receiptMethod]);



  return (
    <>
      <PageHeader
        title="Thông tin đơn hàng"
        heightFull
        pageLeft
        backLink={ROUTER_PATH.CART}
      >
        <form className="w-full flex flex-col gap-48 relative">
          <div className="grid grid-cols-2 gap-4">
            <label className="label-txt col-span-2">Thông tin khách hàng</label>
            <Controller
              name="cus_name"
              rules={rules}
              control={control}
              render={({ field }) => {
                return (
                  <InputText
                    className="col-span-2"
                    placeholder="Tên khách hàng"
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                  />
                );
              }}
            />
            <Controller
              name="cus_phone"
              rules={rules}
              control={control}
              render={({ field }) => (
                <InputText
                  className="col-span-2"
                  placeholder="Số điện thoại"
                  onChange={field.onChange}
                  name={field.name}
                  value={field.value}
                />
              )}
            />

            <label className="label-txt col-span-2">Thông tin giao hàng</label>
            <div className="flex flex-row gap-2 col-span-2">
              {renderShippingMethod}
            </div>

            <div className="col-span-2">
              <Controller
                name="cus_province"
                rules={rules}
                control={control}
                render={({ field }) => (
                  <SelectBox
                    options={statuesUnique}
                    placeholder="Thành phố"
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div className="col-span-2">
              <Controller
                name="cus_district"
                control={control}
                rules={rules}
                render={({ field }) => (
                  <SelectBox
                    options={districts}
                    placeholder="Quận"
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                  />
                )}
              />
            </div>

            {receiptMethod === "N" ? (
              <>
                <div className="col-span-2">
                  <Controller
                    name="cus_ward"
                    rules={receiptMethod === "N" ? rules : null}
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        options={wards}
                        placeholder="Phường"
                        onChange={field.onChange}
                        name={field.name}
                        value={field.value}
                      />
                    )}
                  />
                </div>
                <div className="col-span-2">
                  <Controller
                    name="cus_address"
                    rules={receiptMethod === "N" ? rules : null}
                    control={control}
                    render={({ field }) => (
                      <InputText
                        placeholder="Đường"
                        onChange={field.onChange}
                        name={field.name}
                        value={field.value}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-span-2">
                  <SelectBox
                    options={stores}
                    placeholder="Cửa hàng"
                    onChange={handleSetStore}
                  />
                </div>
                <div className="col-span-2 flex justify-end mt-10 md:absolute md:bottom-8 md:right-0 w-full">
                  <button
                    type="button"
                    className="btn secondary w-full md:w-32"
                    onClick={submitForm}
                  >
                    Tiếp tục
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </PageHeader>
      <PageHeader flip pageRight>
        {receiptMethod === "N" ? (
          <div className="flex-1 flex flex-col gap-1 mt-7">
            <label className="label-txt col-span-2">Cửa hàng</label>
            <p className="col-span-2 des-choose">
              Chọn cửa hàng tiếp nhận đơn hàng
            </p>
            <div className="col-span-2 mt-4">
              <SelectBox
                options={stores}
                placeholder="Cửa hàng"
                onChange={handleSetStore}
              />
            </div>
            <div className="flex justify-between mt-10 md:absolute md:bottom-8 md:right-8 md:w-36">
              <button className="btn secondary w-full" onClick={submitForm}>
                Tiếp tục
              </button>
            </div>
          </div>
        ) : null}
      </PageHeader>
    </>
  );
};
