import { PageHeader, ProductItem } from "components";
import { ROUTER_PATH } from "lib/constants";
import { useAppNavigate } from "hooks";
import { VNDong } from "lib/utils";
import QRCode from "react-qr-code";

import "./OrderContent.scss";
export const OrderContent = ({ orderDetail }) => {
  const { handleNavigatePage } = useAppNavigate();

  const renderInfo = (label, info) => {
    return (
      <div className="info md:col-span-1">
        <label>{label}</label>
        <p>{info}</p>
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title="Chi tiết đơn hàng"
        heightFull
        pageLeft
        backLink={ROUTER_PATH.CHECKOUT}
        contentClassName="md:flex-col"
      >
        <div className="w-full flex flex-col gap-4">
          <span className="label-txt col-span-2">Các sản phẩm</span>
          <div className="wrapper-list flex flex-col gap-2">
            {orderDetail?.items
              ? orderDetail.items.map((order, index) => (
                  <ProductItem
                    key={index}
                    image={order?.image}
                    productTitle={order?.name}
                    price={order?.amount}
                    qty={order?.qty}
                  />
                ))
              : null}
          </div>
        </div>

        <div className="mt-6 group-info">
          <h6 className="label-txt col-span-2 mb-4">Thông tin khách hàng</h6>
          <div className="md:grid md:grid-cols-2 md:gap-x-44">
            {renderInfo("Tên", orderDetail?.cus_name)}
            {renderInfo("Số điện thoại", orderDetail?.cus_phone)}
          </div>
        </div>
      </PageHeader>
      <PageHeader
        flip
        heightFull
        pageRight
        contentClassName="page-right-wrapper "
      >
        <div className="group-info info-shipping flex-1 flex flex-col gap-1 mt-7">
          <h6 className="label-txt col-span-2">Thông tin giao hàng</h6>
          <div className="md:grid md:grid-cols-2 md:gap-x-44">
            {renderInfo("Thành phố", orderDetail?.cus_province)}
            {renderInfo("Phường", orderDetail?.cus_ward)}
            {renderInfo("Quận", orderDetail?.cus_district)}
            {renderInfo("Đường", orderDetail?.cus_address)}
          </div>

          <h6 className="mt-4 label-txt col-span-2 md:mt-6">
            Cửa hàng tiếp nhận đơn hàng
          </h6>

          {renderInfo(orderDetail?.store?.name, orderDetail?.store?.address)}

          <h6 className="mt-4 label-txt col-span-2 md:mt-6">
            QR code để thanh toán đơn hàng
          </h6>
          <p className="qr-desc md:mb-2">Quét mã QR để tiến hành thanh toán</p>
          <div className="flex-none qr-code">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={orderDetail?.payment_url || ''}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div className="summary mt-2">
            <p>Tổng cộng</p>
            <div className="total-price flex flex-row justify-between">
              <p className="label">{orderDetail?.items?.length} sản phẩm</p>
              <p className="price">{VNDong.format(orderDetail?.total)}</p>
            </div>
          </div>
          <div className="w-full md:flex md:justify-end">
            <button
              onClick={() => handleNavigatePage(ROUTER_PATH.CATEGORY)}
              className="btn secondary w-full mt-4 md:w-28"
            >
              Hoàn thành
            </button>
          </div>
        </div>
      </PageHeader>
    </>
  );
};
