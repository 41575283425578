import { ProductContent } from "components";
import { useEffect, useState } from "react";
import { getProductsApi } from "services";
import { useAppContext } from "contexts";

export const ProductPage = () => {
  const [productList, setProductList] = useState([])
  const { isScrollToBottom } = useAppContext()
  const [page, setPage] = useState(1)

  useEffect(() => {
    getProductsApi({page: page, per_page: 100}).then((res) => {
      setProductList( (prev) => {
        const temp = prev.concat(res?.data?.items)
        return temp;
      });
    });
  }, [page]);

  useEffect( () => {
    if (isScrollToBottom) {
      setPage(page + 1)
    }
  }, [isScrollToBottom, setPage])

  return <ProductContent productList={productList} />;
};
