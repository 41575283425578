export const CartIcon = (props) => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_602_2765)">
        <path
          d="M15.8333 17.4168C16.2706 17.4168 16.625 17.0624 16.625 16.6252C16.625 16.1879 16.2706 15.8335 15.8333 15.8335C15.3961 15.8335 15.0417 16.1879 15.0417 16.6252C15.0417 17.0624 15.3961 17.4168 15.8333 17.4168Z"
          stroke={ props.color || '#919EAB'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.125 17.4168C7.56223 17.4168 7.91667 17.0624 7.91667 16.6252C7.91667 16.1879 7.56223 15.8335 7.125 15.8335C6.68778 15.8335 6.33334 16.1879 6.33334 16.6252C6.33334 17.0624 6.68778 17.4168 7.125 17.4168Z"
          stroke={ props.color || '#919EAB'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.791664 0.791504H3.95833L6.08 11.3919C6.15239 11.7564 6.35067 12.0838 6.64014 12.3168C6.9296 12.5498 7.2918 12.6736 7.66333 12.6665H15.3583C15.7299 12.6736 16.0921 12.5498 16.3815 12.3168C16.671 12.0838 16.8693 11.7564 16.9417 11.3919L18.2083 4.74984H4.75"
          stroke={ props.color || '#919EAB'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_602_2765">
          <rect width={19} height={19} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
