import "./ProductCard.scss";
import { useAppNavigate } from "hooks";
import { ROUTER_PATH } from "lib/constants";

export const ProductCard = ({ categoryId, name, price, image, slug }) => {
  const { handleNavigatePage } = useAppNavigate();
  return (
    <div
      className="product-container flex flex-col items-center gap-2"
      onClick={() => handleNavigatePage(`${ROUTER_PATH.PRODUCT}/${categoryId}`)}
    >
      <div className="product-image w-full flex justify-center p-1">
        {image && <img className="" src={image || 'https://pnj.thearchiver99.com/images/product-placeholder.png'} alt="" />}
      </div>
      <div className="product-content">
        <p className="cut-name">{name}</p>
        <span>{price}</span>
      </div>
    </div>
  );
};
