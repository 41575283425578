import { useRef } from "react";
import { useAppContext } from "contexts";
import classNames from "classnames";
import arrowBackBlue from "assets/icons/arrow-back-blue.svg";
import { Link } from "react-router-dom";
import "./PageHeader.scss";
export const PageHeader = ({
  title,
  children,
  hasPadding = true,
  overflowY = true,
  heightFull = false,
  contentClassName = "",
  containerClassName = "",
  pageLeft = false,
  pageRight = false,
  flip = false,
  backLink = "",
}) => {
  const contentRef = useRef(null);
  const { classToFlip, handleSetScrollToBottom } =
    useAppContext();

  
    const renderPageId = () => {
      if (pageLeft) {
        return 'page-left'
      } else if(pageRight) {
        return 'page-right'
      }

      return ''
    }
  const containerClass = classNames(
    "per-page page-header-container flex-none relative",
    containerClassName,
    {
      "pl-5 pr-5 md:pl-8 md:pr-8": hasPadding,
      "h-full": heightFull,
      "page-container-flip": flip,
      "page-left shadow-left": pageLeft,
      "page-right shadow-right": pageRight,
    }
  );
  const headerClass = classNames(
    "flex items-center gap-4 title-container pt-5 pb-4"
  );
  const pageCoverClass = classNames(
    "page-cover w-full flip animation-right-to-left page-left",
    classToFlip,
    {
      "page-left": pageLeft,
      "page-right": pageRight,
    }
  );
  const pageWrapperClass = classNames("wrapper h-full w-full absolute z-10", {
    "page-left": pageLeft,
    "page-right": pageRight,
  });
  const pageContentClass = classNames(
    "page-content md:flex md:mb-10",
    contentClassName,
    {
      "overflow-y-scroll": overflowY,
    }
  );

  const onScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const isBottom = scrollTop + clientHeight === scrollHeight;
    if (isBottom) {
      handleSetScrollToBottom();
    }
  };

  return (
    <div id={renderPageId()} className={containerClass}>
      {/* FLIP */}
      <div className={pageWrapperClass}></div>
      <div className={pageCoverClass}></div>
      {title && (
        <div className={headerClass}>
          {backLink && (
            <Link to={backLink}>
              <img src={arrowBackBlue} alt="" />
            </Link>
          )}
          <h3>{title}</h3>
        </div>
      )}

      <div ref={contentRef} onScroll={onScroll} className={pageContentClass}>
        {children}
      </div>
    </div>
  );
};
