import { useEffect, useState } from "react";
import { OrderContent } from "components";
import { getOrderDetail } from "services";
import { useParams } from "react-router-dom";

export const OrderDetailPage = () => {
  const { code } = useParams();
  const [orderDetail, setOrderDetail] = useState({})

  const orderData = {
    id: 1001,
    name: "Nguyễn Văn Anh",
    address: "01 Nguyễn Trãi, Hồ Chí minh",
    phone: 4234324,
    paymentMethod: "Visa credit",
    status: "Thanh toán thành công",
    products: [
      {
        image: "",
        productTitle: "Bông tai nam bạc đính đá PNJ Silver My man XM00K00180",
        price: 277000,
      },
      {
        image: "",
        productTitle: "Bông tai nam bạc đính đá PNJ Silver My man XM00K00180",
        price: 277000,
      },
      {
        image: "",
        productTitle: "Bông tai nam bạc đính đá PNJ Silver My man XM00K00180",
        price: 277000,
      },
    ],
  };

  useEffect( () => {
    getOrderDetail(code || "ODY9ZG27W6").then( res => {
      let detail = res.data
      let storeName = detail?.store.name
      if (storeName) {
        const temps = storeName.split('PNJ');
        detail.store.address = temps[1] || ''
      }

      setOrderDetail(detail)
    })
  }, [])
  return <OrderContent orderDetail={orderDetail} />;
};
