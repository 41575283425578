import "./DiamondCollection.scss";
import banner from 'assets/images/pnj-banner.png'
import bannerMb1 from 'assets/images/banner_mb1.png'
import bannerMb2 from 'assets/images/banner_mb2.png'

export const DiamondCollection = () => {
  return (
    <div className="diamond-collection flex justify-center items-center">
      <div className="w-full">
        <img className="max-md:hidden" src={banner} alt=""/>
        <div className="md:hidden">
          <img src={bannerMb1} alt=""/>
          <img src={bannerMb2} alt=""/>
        </div>
      </div>
    </div>
  );
};
