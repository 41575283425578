import { useNavigate } from "react-router-dom";
export const useAppNavigate = () => {
  const navigate = useNavigate();
  const handleNextPage = () => {
    navigate(1);
  };

  const handlePrevPage = () => {
    navigate(-1);
  };

  const handleNavigatePage = (link) => {
    navigate(link);
  };

  return {
    handleNextPage,
    handlePrevPage,
    handleNavigatePage,
  };
};
