export const BookIcon = (props) => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_603_2342)">
        <path
          d="M17.4167 2.375H12.6667C11.8268 2.375 11.0214 2.70863 10.4275 3.3025C9.83365 3.89636 9.50002 4.70181 9.50002 5.54167V16.625C9.50002 15.9951 9.75024 15.391 10.1956 14.9456C10.641 14.5002 11.2451 14.25 11.875 14.25H17.4167V2.375Z"
          stroke={ props.color || '#919EAB'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.58334 2.375H6.33334C7.1732 2.375 7.97865 2.70863 8.57251 3.3025C9.16638 3.89636 9.50001 4.70181 9.50001 5.54167V16.625C9.50001 15.9951 9.24979 15.391 8.80439 14.9456C8.35899 14.5002 7.7549 14.25 7.12501 14.25H1.58334V2.375Z"
          stroke={ props.color || '#919EAB'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_603_2342">
          <rect width={19} height={19} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
