import { useEffect } from "react";
import "./App.scss";
import { RootRoute } from "routes/RootRoute";
import { useAuthentication } from "hooks/useAuthentication";
import { useAppContext } from "contexts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  const { authentication } = useAuthentication();
  const { isLoading, setupCountry } = useAppContext();
  useEffect(() => {
    authentication();
    setupCountry();
  }, []);

  return (
    <div className="flex flex-col md:justify-center h-screen overflow-hidden">
      <RootRoute />
      {isLoading ? (
        <div className="loader-wrapper absolute w-full h-full zIndex-9">
          <div className="loader"></div>
        </div>
      ) : null}
    </div>
  );
};

export default App;
