import axios, { HttpStatusCode } from "axios";
import { getAccessTokenCookie, clearCookie } from "lib/utils";
const VERSION_API = process.env.REACT_APP_API;

export const axiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});


axiosClient.interceptors.request.use(
  function (config) {
    config.baseURL = VERSION_API;
    const accessToken = getAccessTokenCookie();
    config.headers.Authorization = ``;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  async (response) => {
    // await sleep();
    return (response.data);
  },
  async (error) => {
    console.log(error)
    if (error.response?.status === 403) {
      window.location.assign('403')
      return
    }
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      clearCookie();
      window.location.assign('403')
      return
    }

    return Promise.reject((error));
  }
);

// export function sleep(ms = 1000): Promise<void> {
//   console.log("Kindly remember to remove `sleep`");
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }


export default axiosClient;
