import VN_COUNTRY from 'assets/country.json'
export const useCountry = () => {
    const uniqueData = (rootData = []) => {
        return rootData.filter( (obj, index) => rootData.findIndex( item => item.label === obj.label) === index)
    }

    const states = VN_COUNTRY.map( item => ({
        value: item.state_code,
        label: item.state_name
    }))

    const districts = VN_COUNTRY.map( item => ({
        value: item.district_code,
        label: item.district_name,
        stateCode: item.state_code,
    }))

    const wards = VN_COUNTRY.map( item => ({
        value: item.ward_code,
        label: item.ward_name,
        stateCode: item.state_code,
        districtCode: item.district_code
    }))

    return { states, districts, wards, uniqueData }

}