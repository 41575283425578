import { PageHeader, ProductImageAnimate, ProductCard } from "components";
import { VNDong } from "lib/utils";
import cartIcon from "assets/icons/shopping-bag.svg";
import "./ProductContent.scss";
import { useEffect, useState } from "react";
import { getProductBySlugApi } from "services";
import { useParams } from "react-router-dom";
import { useCart } from "hooks";
import arrowDown from "assets/icons/show-down-blue.svg";
import videoProduct from "assets/videos/video1.mp4";
import imageTemp1 from "assets/images/diamond-ring.png";
import imageTemp2 from "assets/images/banner_mb2.png";
import imageTemp3 from "assets/images/product-main-mb.png";
import imageTemp4 from "assets/images/product-main-mb.png";
import imageTemp5 from "assets/images/diamond-ring.png";
import imageTemp6 from "assets/images/product-main-mb.png";

import classnames from "classnames";

export const ProductContent = ({ productList = [] }) => {
  const { categoryId } = useParams(); // category is product id
  const { handleAddToCart } = useCart();
  const [productDetail, setProductDetail] = useState();
  const [similarProduct, setSimilarProduct] = useState([]);
  const [isShowDetail, seIsShowDetail] = useState([]);
  useEffect(() => {
    if (productList.length) {
      const products = productList;
      const detailById = products.find((item) => +item.id === +categoryId);
      const productBySlug = products.filter(
        (item) => item?.brand === detailById?.brand
      );
      setSimilarProduct(productBySlug);
      getProductBySlugApi(detailById?.slug).then((res) => {
        const detail = res.data;

        if (!detail.mp_product_main_feature_format.length) {
          detail.mp_product_main_feature_format =
            "<span>Chủng loại: Nhẫn</span><span>Màu chất liệu: Màu khác</span><span>Chất liệu: Bạc</span><span>Giới tính: Nữ</span><span>Hình dạng đá: Tròn</span><span>Màu đá chính: Trắng</span><span>Loại đá chính: CZ</span>";
        }

        if (detail.images.length < 2) {
          // TODO TEST
          detail.images.push(imageTemp1)
          detail.images.push(imageTemp2)
          detail.images.push(imageTemp3)
          detail.images.push(imageTemp4)
          detail.images.push(imageTemp5)
          detail.images.push(imageTemp6)
        }

        if (!detail.video) {
          detail.video = 'https://cdn.pnj.io/images/videos/resize_GL0000W000566.mp4'
        } 
        setProductDetail(detail);
      });
    }
  }, [productList, categoryId]);

  const onAddToCart = () => {
    handleAddToCart(categoryId);
  };

  return (
    <>
      <PageHeader title="Thông tin sản phẩm" overflowY={false} pageLeft>
        {productDetail && (
          <div className="product-content-container w-full grid md:block gap-4 md:grid-rows-2">
            <div className="flex flex-col justify-center row-span-2 md:row-span-1">
              <ProductImageAnimate
                images={productDetail?.images ?? []}
                video={productDetail?.video ?? ''}
              />
            </div>
            <div className="product-des flex flex-col row-span-1 md:pt-4 md:pb-4">
              <div>
                <div className="flex justify-between">
                  <p className="product-title cut-name">
                    {productDetail?.name}
                  </p>
                  <span
                    onClick={onAddToCart}
                    className="bag flex justify-center ml-3"
                  >
                    <img src={cartIcon} alt="bag" />
                  </span>
                </div>

                <span>{VNDong.format(productDetail?.sell_price)}</span>
                <div className="btn-show-more flex flex-row gap-2 mt-2 mb-2">
                  <p
                    className="load-more"
                    onClick={() => seIsShowDetail(!isShowDetail)}
                  >
                    Thông tin sản phẩm
                  </p>
                  <img
                    className={classnames({
                      "show-less": isShowDetail,
                    })}
                    src={arrowDown}
                    alt="arrow down icon"
                  />
                </div>
                {isShowDetail && (
                  <div
                    className="detail-content flex flex-col"
                    dangerouslySetInnerHTML={{
                      __html: productDetail?.mp_product_main_feature_format,
                    }}
                  />
                )}

                {/* Với thiết kế lấy cảm hứng từ biểu tượng vô cực tựa như sợi dây
                  kết nối giữa các thành viên trong gia đình, PNJ mang đến đôi
                  bông tai trẻ trung và tinh tế */}
              </div>

              {/* <div className="flex gap-2 mt-6">
                <span className="tag active">Loại đá chính</span>{" "}
                <span className="tag">Loại đá phụ</span>
              </div> */}
              {/* <div className="flex gap-2 mt-3">
                <button className="btn plus" onClick={onAddToCart}>Add to card</button>
                <button className="btn secondary">AR Conner</button>
              </div> */}
            </div>
          </div>
        )}
      </PageHeader>
      <PageHeader flip title="Sản phẩm bạn có thể thích" pageRight>
        <div className="grid grid-cols-2 gap-y-5 gap-x-2 pb-4 md:pl-14 md:pr-14 md:gap-x-20 md:pt-9 md:pb-20">
          {similarProduct.length ?
            similarProduct.map((product, index) => (
              <div key={`${product.name}-${index}`} className="col-span-1">
                <ProductCard
                  name={product.name}
                  price={VNDong.format(product.sell_price)}
                  image={product?.images ? product?.images[0] : ""}
                  categoryId={product?.id}
                />
              </div>
            )) : null}
        </div>
      </PageHeader>
    </>
  );
};
